import { useContext, useEffect, useState } from "react"
import { Button, Card, DatePicker, Divider, Form, Input } from "antd"
import dayjs from "dayjs"
import { Event } from "../api"
import { CurrentUserContext, useHttp } from "../App"
import { useNavigate } from "react-router-dom";


export function EventEdit(props: { eventId: string | undefined }) {
    const [eventState, setEvent] = useState<{
        participantCount: number,
        gameCount: number,
        date: dayjs.Dayjs,
        propositionsDeadline?: dayjs.Dayjs,
        preferencesDeadline?: dayjs.Dayjs,
        short?: string
    }>({
        gameCount: 0,
        participantCount: 0,
        date: dayjs().minute(0).second(0)
    })

    const { currentUser } = useContext(CurrentUserContext)
    const httpFetch = useHttp()
    const fetchEvent = () => {
        if (props.eventId === undefined) {
            return
        }
        httpFetch(`/api/event/${props.eventId}`).then((resp: any) => {
            resp.json().then((data: Event) => {
                setEvent({
                    participantCount: data.participantCountLimit || 0,
                    gameCount: data.gamePropositionCountLimit || 0,
                    date: data.eventDate ? dayjs(data.eventDate) : dayjs(),
                    preferencesDeadline: data.preferencesDeadline ? dayjs(data.preferencesDeadline) : undefined,
                    propositionsDeadline: data.propositionsDeadline ? dayjs(data.propositionsDeadline) : undefined,
                    short: data.short
                })
            })
        })
    }
    const navigate = useNavigate()
    const saveEvent = () => {
        httpFetch(`/api/event/${props.eventId || "_"}`, {
            method: "PUT", body: JSON.stringify({
                participantCountLimit: eventState.participantCount,
                gamePropositionCountLimit: eventState.gameCount,
                eventDate: eventState.date.toJSON(),
                preferencesDeadline: eventState.preferencesDeadline?.toJSON(),
                propositionsDeadline: eventState.propositionsDeadline?.toJSON(),
                short: eventState.short
            })
        }).then((resp: any) => {
            resp.json().then((data: Event) => {
                if (props.eventId) {
                    fetchEvent()
                } else {
                    navigate("/event/" + data.id + "/edit")
                }
            })
        })
    }
    useEffect(fetchEvent, [props.eventId, currentUser, httpFetch])

    return (
        <Card>
            <Form.Item label="liczba graczy">
                <Input type="number" value={eventState.participantCount} onChange={(e) => setEvent(Object.assign({}, eventState, { participantCount: +e.target.value }))} />
            </Form.Item>

            <Form.Item label="liczba gier">
                <Input type="number" value={eventState.gameCount} onChange={(e) => setEvent(Object.assign({}, eventState, { gameCount: +e.target.value }))} />
            </Form.Item>

            <Form.Item label="data wydarzenia">
                <DatePicker
                    value={eventState.date}
                    type="date"
                    showTime={{ format: "HH:mm", minuteStep: 5 }}
                    showNow={false}
                    allowClear={false}
                    onChange={(dat) => setEvent(Object.assign({}, eventState, { date: dat }))}
                />
            </Form.Item>

            <Form.Item label="deadline propozycji">
                <DatePicker
                    value={eventState.propositionsDeadline}
                    type="date"
                    showTime={{ format: "HH:mm", minuteStep: 5 }}
                    showNow={false}
                    onChange={(dat) => setEvent(Object.assign({}, eventState, { propositionsDeadline: dat }))}
                    maxDate={eventState.date}
                />
            </Form.Item>

            <Form.Item label="deadline preferencji">
                <DatePicker
                    value={eventState.preferencesDeadline}
                    type="date"
                    showTime={{ format: "HH:mm", minuteStep: 5 }}
                    showNow={false}
                    onChange={(dat) => setEvent(Object.assign({}, eventState, { preferencesDeadline: dat }))}
                    maxDate={eventState.date}
                />
            </Form.Item>
            <Divider />
            <Form.Item label="short link">
                <Input type="text" value={eventState.short} onChange={(e) => setEvent(Object.assign({}, eventState, { short: e.target.value }))} />
            </Form.Item>
            <Divider />
            <Button type="primary" onClick={saveEvent}>Zapisz</Button>
        </Card>
    )

}

