import {createBrowserRouter, createRoutesFromElements, Route, useParams} from "react-router-dom"
import { EventView } from "./components/EventView"
import { Outline } from "./components/Outline"
import { MeProfile } from "./components/UserProfile"
import { EventEdit } from "./components/EventEdit"


const PseudoEventView= () => {
    const params = useParams()
    return (
        <span>
            <EventView eventId={params.eventId || ""}/>
        </span>
    )
}

const PseudoEventEdit= () => {
    const params = useParams()
    return (
        <span>
            <EventEdit eventId={params.eventId}/>
        </span>
    )
}

function Default() {
    return (
      <div>
          <h1>BoardPal</h1>
      </div>
    );
  }

export const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<Outline/>}>
        <Route 
            path="/"
            element={<Default/>}
        />
        <Route 
            path="/event/:eventId"
            element={<PseudoEventView/>}
        />
        <Route 
            path="/event/_/edit"
            element={<PseudoEventEdit/>}
        />
        <Route 
            path="/event/:eventId/edit"
            element={<PseudoEventEdit/>}
        />
        <Route 
            path="/user/me"
            element={<MeProfile/>}
        />
    </Route>
))

