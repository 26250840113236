import { createContext, useContext, useEffect, useState } from 'react';
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { UserView } from "./api";
import { httpFetch, HttpFetch } from "./fetch";
import { notification } from 'antd';

export const CurrentUserContext = createContext<{currentUser:UserView | undefined | null, setCurrentUser: (u: UserView | null) => any}>(undefined as any)
const HttpContext = createContext<HttpFetch>(httpFetch)

export const useHttp = () => useContext(HttpContext)
export const useCurrentUser = () => useContext(CurrentUserContext)

export function App() {
    const [currentUser, setCurrentUser] = useState<UserView | undefined | null>(undefined);
    const [[http], setHttp] = useState<HttpFetch[]>([httpFetch])
    const [notifications, contextHolder] = notification.useNotification();
    useEffect(() => {
        const http = (url: URL | string, init?: RequestInit): Promise<Response> => {
            const r = httpFetch(url, init)
            r.catch((reason) => {
                console.log({reason})
                notifications.error({message: "Błąd połączenia", duration: 5, showProgress: true, pauseOnHover: true})
                throw reason
            })
            r.then((res) => {
                if (res.status >= 500) {
                    notifications.error({message: "Błąd serwera", duration: 5, showProgress: true, pauseOnHover: true})
                } else if (res.status >= 400 && res.status !== 401 && res.status !== 403) {
                    notifications.error({message: "Coś poszło nie tak", duration: 5, showProgress: true, pauseOnHover: true})
                }   else if (res.status === 403) {
                    notifications.error({message: "Brak uprawnień do operacji", duration: 5, showProgress: true, pauseOnHover: true})
                }
            })
            return r
        }
        setHttp([http])
    }, [notifications])

    return (
        <CurrentUserContext.Provider value={{currentUser, setCurrentUser}}>
            <HttpContext.Provider value={http}>
                {contextHolder}
                <RouterProvider router={router}/>
            </HttpContext.Provider>
        </CurrentUserContext.Provider>
    )
}
