import { Event } from "../../api";
import { EventStatus } from "../EventView";
import dayjs from "dayjs"

export function Deadline(props: { event: Event }): (JSX.Element | null) {
    const event = props.event;
    if ((event.status === EventStatus.PropositionsWait || event.status === EventStatus.Propositions) && event.propositionsDeadline) {
        return (<h3>Termin: {dayjs(event.propositionsDeadline).format("YYYY-MM-DD HH:mm")}</h3>)
    } else if ((event.status === EventStatus.PreferencesWait || event.status === EventStatus.Preferences) && event.preferencesDeadline) {
        return (<h3>Termin: {dayjs(event.preferencesDeadline).format("YYYY-MM-DD HH:mm")}</h3>)
    }

    return null
}
