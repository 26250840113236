export function registerWidgetBot(el : HTMLElement | null) {
    const scr = document.createElement("script");
    scr.src = 'https://cdn.jsdelivr.net/npm/@widgetbot/crate@3';
    scr.async = true;
    scr.defer = true;
    scr.text = `
  new Crate({
    server: '1248520891923697674', // Planszówki w Łosiu
    channel: '1248520892397518934', // #spotkanie-plany
})`;
    el?.appendChild(scr)
}
